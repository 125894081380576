import { createStore } from 'vuex'

export default createStore({
  state: {
    safetyTop: null,
    userName: "",
    userIdNum: ""
  },
  getters: {
  },
  mutations: {
    setSafetyArea(state, val) {
      state.safetyTop = val;
    },
    setUserInfo(state, obj){
      let data = JSON.parse(obj);
      state.userName = data.name;
      // 脱敏显示身份证号码（这里只是简单示例，实际脱敏逻辑可能更复杂）  
      let idNumDisplay = data.idNum.replace(/(\d{6})\d*(\d{4})/, '$1********$2');
      state.userIdNum = idNumDisplay;
    }
  },
  actions: {
  },
  modules: {
  }
})
