<template>
  <div class="wrap">
    <div :class="['topPop', {'on': isScrolled}]">
      <NavBer title="我的课程"></NavBer>
      <div class="hd">
        <div :class="['li', {'on': active === 0}]" @click="cutFn(0)">热门免费课程</div>
        <div :class="['li', {'on': active === 1}]" @click="cutFn(1)">我的课程</div>
      </div>
    </div>
    <main>
      <van-list
          class="list"
          v-model="listLoad"
          :finished="finished"
          finished-text="我是有底线的"
          @load="onLoad"
        >
        <div class="li" @click="$router.push('/videoDetail')">
          <div class="dt">
            <img src="~@/assets/images/home/coursePic1.jpg" alt="">
            <div class="btn"></div>
          </div>
          <div class="dd">
            <h4>房建基础知识篇</h4>
            <p>施工现场安全培训干货总结货总结货总结货总结货总结</p>
          </div>
        </div>
        <div class="li" @click="$router.push('/videoDetail')">
          <div class="dt">
            <img src="~@/assets/images/home/coursePic2.jpg" alt="">
            <div class="btn"></div>
          </div>
          <div class="dd">
            <h4>房建基础知识篇</h4>
            <p>施工现场安全培训干货总结货总结货总结货总结货总结</p>
          </div>
        </div>
        <div class="li" @click="$router.push('/videoDetail')">
          <div class="dt">
            <img src="~@/assets/images/home/coursePic3.jpg" alt="">
            <div class="btn"></div>
          </div>
          <div class="dd">
            <h4>房建基础知识篇</h4>
            <p>施工现场安全培训干货总结货总结货总结货总结货总结</p>
          </div>
        </div>
      </van-list>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer';
import { ref, reactive, onMounted } from "vue";
const finished = ref(false);
const listLoad = ref(true);
const isScrolled = ref(false);
const active = ref(0);
// 加载更多
const onLoad = () => {
  if(listLoad.value) return
  seekFn()
}
// 切换选项
const cutFn = (num) => {
  active.value = num;
}

onMounted(() => {
    window.addEventListener('scroll',function(){
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        isScrolled.value = scrollTop > 60;
    })
})
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  padding-top: 1.9rem;
}
main{
    padding: .6rem .3rem;
    background: #f3f4f6;
}
.topPop{
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  &.on{
    box-shadow: 0 0 .2rem rgba(0,0,0,.1);
  }
  .navBer{
    position: relative;
  }
}
.hd{
  position: relative;
  z-index: 1000;
  width: 100%;
  border-top: .01rem solid #f3f4f6;
  display: flex;
  padding: 0 .3rem;
  background: #fff;
  .li{
    width: 50%;
    height: .8rem;
    line-height: .8rem;
    position: relative;
    font-size: .22rem;
    color: #5b5d6a;
    text-align: center;
    transition: all .3s;
    &.on{
      color: #007df8;
      font-size: .26rem;
      &:after{
        opacity: 1;
      }
    }
    &:after{
      position: absolute;
      left: 0;
      right: 0;
      bottom: .1rem;
      margin: 0 auto;
      content: "";
      display: block;
      width: .4rem;
      height: .04rem;
      border-radius: .04rem;
      background: linear-gradient(to right, #fff, #1c8bf9);
      opacity: 0;
      transition: all .3s;
    }
  }
}
.list{
  .li{
    overflow: hidden;
    border-radius: .2rem;
    background: #fff;
    margin-bottom: .3rem;
    &:last-child{
      margin-bottom: 0;
    }
    .dt{
      position: relative;
      .btn{
        position: absolute;
        left: 50%;
        top: 50%;
        z-index: 5;
        width: .7rem;
        height: .7rem;
        margin: -.35rem 0 0 -.35rem;
        background: url(~@/assets/images/iconPlay.png) no-repeat center;
        background-size: contain;
      }
    }
    .dd{
      padding: .2rem .3rem;
      h4{
        font-size: .28rem;
        color:#43465a;
        line-height: .38rem;
      }
      p{
        font-size: .22rem;
        color:#5b5d6a;
        line-height: .36rem;
      }
    }
  }
}
</style>
  
  