import { createRouter, createWebHistory, createWebHashHistory } from 'vue-router'
import Home from '../views/index.vue'
import Zoology from '../views/home/zoology.vue'
import Course from '../views/home/course.vue'
import VideoDetail from '../views/home/videoDetail.vue'

import Personal from '../views/personal/personal.vue'
import PersonalExcellentScore from '../views/personal/excellentScore.vue'

import Shop from '../views/shop/shop.vue'
import ShopId from '../views/shop/id.vue'


const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/zoology',
    name: 'zoology',
    component: Zoology
  },
  {
    path: '/course',
    name: 'course',
    component: Course
  },
  {
    path: '/videoDetail',
    name: 'videoDetail',
    component: VideoDetail,
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/personal',
    name: 'personal',
    component: Personal
  },
  {
    path: '/personalExcellentScore',
    name: 'personalExcellentScore',
    component: PersonalExcellentScore,
    meta: {
      closeNav: true 
    }
  },
  {
    path: '/shop',
    name: 'shop',
    component: Shop
  },
  {
    path: '/shopId',
    name: 'shopId',
    component: ShopId
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), //历史模式
  // history: createWebHashHistory(process.env.BASE_URL), //hash模式
  routes,
  scrollBehavior(to, from, savedPosition){
    return { top: 0 };
  }
})

export default router
