import { useRouter } from 'vue-router';
import { showDialog } from 'vant';

export function usePublic() {
    const router = useRouter();
    // 跳转
    function skipLink(str){
        router.push(str)
    }
    return {
        skipLink
    }
}

// app跳转链接
export function skipOutsideChain(url){
    window.location.href = `bridge:/webview/open/pageInit?url=${encodeURIComponent(url)}`
}

// 敬请期待
export function expect(){
    showDialog({
        title: '敬请期待'
    }).then(() => {
        // on close
    });
}