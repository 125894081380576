<template>
  <div class="wrap">
    <NavBer title="工友商城"></NavBer>
    <main>
        <div class="introduce">
            <h4>什么是工友商城？</h4>
            <p>工友商城是为广大工友量身定做的服务生态商城，包含医疗健康、保险保障、学习培训、生活用品、金融服务等于一体。通过平台力量引入优质供应商，在保障品质的前提下为工友提供优惠的平台团购价，切实保障广大工友的工作与生活需求，目前正紧张招商中。</p>
        </div>
        <div class="itemBox">
            <router-link class="a on" to="/course">
                <div class="icon"><img src="~@/assets/images/home/zoologyBtnIconOn1.png" alt=""></div>
                <div class="p">我的课程</div>
            </router-link>
            <div class="a" @click="loginJump(1, 'bridge:/baseWorker/openWorkerLoanView/pageInit')">
                <div class="icon"><img src="~@/assets/images/home/zoologyBtnIcon2.png" alt=""></div>
                <div class="p">我的借支</div>
            </div>
            <div class="a" @click="expect">
                <div class="icon"><img src="~@/assets/images/home/zoologyBtnIcon3.png" alt=""></div>
                <div class="p">我的体检</div>
            </div>
            <div class="a" @click="expect">
                <div class="icon"><img src="~@/assets/images/home/zoologyBtnIcon4.png" alt=""></div>
                <div class="p">我的保险</div>
            </div>
        </div>
        <div class="companyWr">
          <div class="til">
            <h2>生态企业</h2>
            <div class="p">排名不分先后</div>
          </div>
          <ul>
            <li><img src="~@/assets/images/home/zoologyLogo1.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo2.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo3.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo4.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo5.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo6.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo7.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo8.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo9.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo10.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo11.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo12.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo13.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo14.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo15.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo16.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo17.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo18.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo19.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo20.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo21.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo22.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo23.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo24.png" alt=""></li>
            <li><img src="~@/assets/images/home/zoologyLogo25.png" alt=""></li>
          </ul>
          <van-button type="primary" class="consultBtn" color="#007df8" @click="expect">招商咨询</van-button>
        </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer';
import { expect } from '@/api/public';
import { ref, reactive, onMounted } from "vue";
import { useRouter } from 'vue-router';
const router = useRouter();

const prestoreId = ref(-1);
const prestoreUrl = ref('');

// 业务按钮跳转
const loginJump = (num, url) => {
  prestoreId.value = num
  prestoreUrl.value = url
  window.location.href = "bridge:/base/getAppUserLogin/pageInit?callback=callbackLoginEvent";
}

onMounted(function(){
    // 登录状态
    window.callbackLoginEvent = (isLogin) => {
      // isLogin 1 已登录 0 未登录
      if (isLogin == '1') {
        if(prestoreId.value == 1){
          window.location.href = prestoreUrl.value
        }else if(prestoreId.value == 0){
          router.push(prestoreUrl.value)
        }
        prestoreId.value = -1
        prestoreUrl.value = ""
      }else {
        prestoreId.value = -1
        prestoreUrl.value = ""
        // *****  调起登录页面的协议
        window.location.href = "bridge:/base/openLoginView/pageInit?callback=callbackLoginEvent";
      }
    }
})

</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  background: #f3f4f6;
  :deep(.navBer){
    .retBtn{
      display: none;
    }
  }
}
main{
    padding: .6rem .3rem;
    background: #f3f4f6;
}
.introduce{
  padding: .3rem;
  background: #fff url(~@/assets/images/home/zoologyBoxBg.jpg) no-repeat top center;
  background-size: 100%;
  border-radius: .2rem;
  h4{
    font-size: .28rem;
    color: #3c3f50;
    line-height: .4rem;
    margin-bottom: .1rem;
  }
  p{
    font-size: .22rem;
    color: #5b5d6a;
    line-height: .36rem;
  }
}
.itemBox{
  padding: .5rem 0;
  display: flex;
  justify-content: space-between;
  .a{
    width: 1.08rem;
    .icon{
      width: .88rem;
      height: .88rem;
      border-radius: .3rem;
      overflow: hidden;
      margin: 0 auto .15rem;
      box-shadow: 0 0 .5rem rgba(0,0,0,.06);
    }
    .p{
      font-size: .26rem;
      color: #43465a;
      text-align: center;
      line-height: .36rem;
    }
  }
  .on{
    .icon{
      box-shadow: none;
    }
  }
}
.companyWr{
  .til{
    position: relative;
    padding-bottom: .15rem;
    border-bottom: .04rem solid #dcdcdc;
    margin-bottom: .5rem;
    &:after{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      right: 0;
      width: 1rem;
      height: .04rem;
      margin: 0 auto;
      background: #007df8;
      bottom: -.04rem;
    }
    h2{
      font-size: .4rem;
      color: #3c3f50;
      text-align: center;
      font-weight: 600;
    }
    .p{
      font-size: .22rem;
      color: #5b5d6a;
      text-align: center;
    }
  }
  ul{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0 -.1rem;
  }
  li{
    position: relative;
    width: 1.28rem;
    height: .8rem;
    overflow: hidden;
    margin: 0 .06rem .12rem;
    img{
      position: absolute;
      left: 50%;
      height: 100%;
      transform: translateX(-50%);
      max-width: none;
    }
  }
  .consultBtn{
    margin-top: .4rem;
    display: block;
    height: 1.1rem;
    border-radius: .3rem;
    font-size: .28rem;
    width: 100%;
  }
}
</style>
  
  