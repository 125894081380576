<template>
  <div class="wrap">
    <NavBer title="我的优秀分"></NavBer>
    <main>
        <div class="ifon">
            <div class="dt">
                <img src="~@/assets/images/personal/personalVia.jpg" alt="">
            </div>
            <div class="dd">
                <div class="name">周杰伦</div>
                <div class="time">上次更新:2024-05-11</div>
            </div>
        </div>
        <div class="content">
            <div class="chart">
                <div class="box" ref="echarRef"></div>
                <div class="total">700</div>
                <div class="p">近期变化情况:<em class="i1">平稳</em>、<em class="i2">稳增</em>、<em class="i3">小降</em></div>
            </div>
            <div class="txt">
                <p>说明：行业优秀分是通过华建通大数据，已行业从业人员的保险保障、工作奖惩记录、健康情况、技能水平、工单效率、项目履历等多个维度进行统计与计算得出的参考分值，在一定程度上反应您的优秀水平</p>
                <p>行业优秀分会随着您参与项目工程、参与工作、参与学习培训、参加体检等生产活动而不断的修正。</p>
                <p>什么可以加分？参与工程累计经验、定期体检、购买保险、参加学习提高能力都可以有机会增加优秀分</p>
                <p>什么会减分？受到企业记录的批评、存在工作违纪行为都会一定程度上有减分风险</p>
            </div>
        </div>
    </main>
  </div>
</template>
<script setup>
import * as echarts from "echarts";
import NavBer from '@/components/NavBer';
import { ref, reactive, onMounted } from "vue";
const echarRef = ref(null);
const initChart = () => {
    const chartInstance = echarts.init(echarRef.value);
    const option = {
        radar: {
            radius: "85%",
            startAngle: 120,
            axisName: {
                color: '#3c3f50'
            },
            tooltip: {
                trigger: 'axis'
            },
            splitNumber: 5,
            axisLine: {
                lineStyle: {
                    color: '#d2d2d2'
                }
            },
            splitLine: {
                lineStyle: {
                    color: '#d2d2d2'
                }
            },
            splitArea: {
                show: false
            },
            indicator: [
                { text: '保障', max: 100 },
                { text: '履历', max: 100 },
                { text: '效率', max: 100 },
                { text: '技能', max: 100 },
                { text: '健康', max: 100 },
                { text: '奖惩', max: 100 }
            ]
        },
        series: [
            {
                type: 'radar',
                symbol: 'none',
                itemStyle: {
                    color: '#007df8'
                },
                areaStyle: {
                    color: '#007df8'
                },
                tooltip: {
                    trigger: 'item'
                },
                data: [
                    {
                        value: [60, 60, 60, 80, 60, 60],
                        name: '近期变化情况'
                    }
                ]
            }
        ]
    };
    chartInstance.setOption(option);
}
onMounted(() => {
    initChart();
})

</script>
<style scoped lang="scss">
.wrap {
  position: relative;
}
main{
    padding: 0 .3rem .6rem;
    background: #f3f4f6 url(~@/assets/images/personal/excellentScoreBg.jpg) no-repeat top center;
    background-size: 100%;
}
.ifon{
    padding: .4rem 0 .3rem;
    display: flex;
    .dt{
        position: relative;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: linear-gradient(to right top, #1a8af9, #abd5fe);
        margin-right: .3rem;
        overflow: hidden;
        padding: .04rem;
        img{
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .dd{
        padding-top: .15rem;
        .name{
            font-size: .3rem;
            color: #3c3f50;
            line-height: .4rem;
            margin-bottom: .05rem;
        }
        .time{
            font-size: .22rem;
            color: #5b5d6a;
            line-height: .32rem;
        }
    }
}

.content{
    border-radius: .2rem;
    background: #fff;
    padding: .2rem;
    .txt{
        padding: .2rem;
        border-radius: .1rem;
        background: #f7fbff;
        p{
            font-size: .22rem;
            color: #5b5d6a;
            margin-bottom: .36rem;
            &:last-child{
                margin-bottom: 0;
            }
        }
        // background: #;
    }
}
.chart{
    position: relative;
    .box{
        height: 5.4rem;
    }
    .total{
        position: absolute;
        left: 50%;
        top: 40%;
        font-size: .4rem;
        color: #fff;
        line-height: .6rem;
        font-family: 'DIN-Bold';
        transform: translateX(-50%);
        z-index: 50;
    }
    .p{
        margin-bottom: .2rem;
        font-size: .24rem;
        color: #333;
        text-align: center;
        .i1{
            color: #1bcb8f;
        }
        .i2{
            color: #fb5a56;
        }
        .i3{
            color: #11a9fd;
        }
    }
}
</style>
  
  