<template>
  <div class="wrap">
    <div :class="['topBox', {'on': isScrolled}]" :style="{ paddingTop: $store.state.safetyTop + 'px'}">
      <div class="seek">
        <div class="ipt">
          <input
            ref="ipt"
            type="text"
            placeholder="请输入你想要的内容"
            v-model="value"
          />
          <div class="icon"></div>
          <div class="clear" @click="clearIpt" v-show="value.length > 0"></div>
        </div>
        <div class="btn" @click="seekFn">搜索</div>
      </div>
      <div class="cTabs">
        <ul>
          <li :class="['it1', {'on': active === 0}]" @click="getList(0)"><em>综合</em></li>
          <li :class="['it2', {'on': active === 1}]" @click="getList(1)"><em>销量</em></li>
          <li :class="['it3', {'on': active === 2}]" @click="getList(2)"><em>价格</em></li>
          <li :class="['it4', {'on': active === 3}]" @click="filtrateFn"><em>筛选</em></li>
        </ul>
      </div>
    </div>
    <main>
      <div class="bd">
        <van-list
          class="list"
          v-model="listLoad"
          :finished="finished"
          finished-text="我是有底线的"
          @load="onLoad"
        >
          <div class="li">
            <router-link to="/ShopId" class="a">
              <div class="dt">
                <img src="~@/assets/images/shop/shopPic1.jpg" alt="" />
              </div>
              <div class="dd">
                <h4>摩恩面盆龙头冷热出水单把双孔纯铜镀铬超长寿命加厚管壁可...</h4>
                <div class="supplier">厂家：摩恩</div>
                <div class="price">¥58,981</div>
                <div class="p">
                  <em>100+</em> 人关注&nbsp;&nbsp;<em>20+</em> 收藏
                </div>
                <div class="labels">
                  <div class="i">摩恩品牌旗舰店</div>
                </div>
              </div>
            </router-link>
            <div class="addShopping"></div>
          </div>
          <div class="li">
            <router-link to="/ShopId" class="a">
              <div class="dt">
                <img src="~@/assets/images/shop/shopPic2.jpg" alt="" />
              </div>
              <div class="dd">
                <h4>标题文字最多三十个汉字最多显示两行导入的数据多余三个汉...</h4>
                <div class="supplier">厂家：摩恩</div>
                <div class="price">¥58,981</div>
                <div class="p">
                  <em>100+</em> 人关注&nbsp;&nbsp;<em>20+</em> 收藏
                </div>
                <div class="labels">
                  <div class="i">摩恩品牌旗舰店</div>
                </div>
              </div>
            </router-link>
            <div class="addShopping"></div>
          </div>
          <div class="li">
            <router-link to="/ShopId" class="a">
              <div class="dt">
                <img src="~@/assets/images/shop/shopPic3.jpg" alt="" />
              </div>
              <div class="dd">
                <h4>标题文字最多三十个汉字最多显示两行导入的数据多余三个汉...</h4>
                <div class="supplier">厂家：摩恩</div>
                <div class="price">¥58,981</div>
                <div class="p">
                  <em>100+</em> 人关注&nbsp;&nbsp;<em>20+</em> 收藏
                </div>
                <div class="labels">
                  <div class="i">摩恩品牌旗舰店</div>
                </div>
              </div>
            </router-link>
            <div class="addShopping"></div>
          </div>
          <div class="li">
            <router-link to="" class="a">
              <div class="dt">
                <img src="~@/assets/images/shop/shopPic1.jpg" alt="" />
              </div>
              <div class="dd">
                <h4>摩恩面盆龙头冷热出水单把双孔纯铜镀铬超长寿命加厚管壁可...</h4>
                <div class="supplier">厂家：摩恩</div>
                <div class="price">¥58,981</div>
                <div class="p">
                  <em>100+</em> 人关注&nbsp;&nbsp;<em>20+</em> 收藏
                </div>
                <div class="labels">
                  <div class="i">摩恩品牌旗舰店</div>
                </div>
              </div>
            </router-link>
            <div class="addShopping"></div>
          </div>
        </van-list>
      </div>
    </main>
    <!-- 筛选弹窗 -->
    <van-popup class="filtrateRight"
        v-model:show="showRight"
        position="right"
        :style="{ width: '84%', height: '100%' }">
        <div class="box b1">
            <div class="til">分类</div>
            <div class="moreBtn" @click="showClassify = true">全部分类</div>
            <div class="p" v-if="classifyText">{{ classifyText }}</div>
        </div>
        <div class="box b2">
            <div class="til">价格</div>
            <div class="scope">
                <input type="number" placeholder="最低价" :maxlength="10">
                <div class="line"></div>
                <input type="number" placeholder="最高价" :maxlength="10">
            </div>
        </div>
        <div class="box b3">
            <div class="til">品牌</div>
            <ul>
                <li @click="brandSelect(0)" :class="{'act': brandAct.includes(0)}"><em>品牌名称</em></li>
                <li @click="brandSelect(1)" :class="{'act': brandAct.includes(1)}"><em>品牌名称</em></li>
                <li @click="brandSelect(2)" :class="{'act': brandAct.includes(2)}"><em>品牌名称</em></li>
                <li @click="brandSelect(3)" :class="{'act': brandAct.includes(3)}"><em>品牌名称</em></li>
                <li @click="brandSelect(4)" :class="{'act': brandAct.includes(4)}"><em>品牌名称</em></li>
                <li @click="brandSelect(5)" :class="{'act': brandAct.includes(5)}"><em>品牌名称</em></li>
                <li @click="brandSelect(6)" :class="{'act': brandAct.includes(6)}"><em>品牌名称</em></li>
                <li @click="brandSelect(7)" :class="{'act': brandAct.includes(7)}"><em>品牌名称</em></li>
                <li @click="brandSelect(8)" :class="{'act': brandAct.includes(8)}"><em>品牌名称</em></li>
                <li @click="brandSelect(9)" :class="{'act': brandAct.includes(9)}"><em>品牌名称</em></li>
                <li @click="brandSelect(10)" :class="{'act': brandAct.includes(10)}"><em>品牌名称</em></li>
                <li class="all" @click="showBrand = true"><em>全部品牌</em></li>
            </ul>
        </div>
    </van-popup>
    <!-- 分类弹窗 -->
    <van-popup class="indexBar"
        v-model:show="showClassify"
        position="right"
        :style="{ width: '84%', height: '100%' }">
        <div class="showBox">
            <div class="l">已选：</div>
            <div class="r">
                <div class="i">全部分类</div>、
                <div class="i">A建材分类1</div>、
                <div class="i">B建材分类3</div>、
                <div class="i">C建材分类2</div>
            </div>
        </div>
        <van-index-bar>
            <van-checkbox-group v-model="checked" shape="square">
                <van-checkbox name="all">全部类型</van-checkbox>
                <van-index-anchor index="A"/>
                <van-checkbox name="a1">A建材分类1</van-checkbox>
                <van-checkbox name="a2">A建材分类2</van-checkbox>
                <van-checkbox name="a3">A建材分类3</van-checkbox>
                <van-checkbox name="a4">A建材分类4</van-checkbox>
                <van-checkbox name="a5">A建材分类5</van-checkbox>
                <van-checkbox name="a6">A建材分类6</van-checkbox>
                <van-index-anchor index="B"/>
                <van-checkbox name="b1">B建材分类1</van-checkbox>
                <van-checkbox name="b2">B建材分类2</van-checkbox>
                <van-checkbox name="b3">B建材分类3</van-checkbox>
                <van-checkbox name="b4">B建材分类4</van-checkbox>
                <van-checkbox name="b5">B建材分类5</van-checkbox>
                <van-checkbox name="b6">B建材分类6</van-checkbox>
                <van-index-anchor index="C"/>
                <van-checkbox name="c1">C建材分类1</van-checkbox>
                <van-checkbox name="c2">C建材分类2</van-checkbox>
                <van-checkbox name="c3">C建材分类3</van-checkbox>
                <van-checkbox name="c4">C建材分类4</van-checkbox>
                <van-checkbox name="c5">C建材分类5</van-checkbox>
                <van-checkbox name="c6">C建材分类6</van-checkbox>
                <van-index-anchor index="D"/>
                <van-checkbox name="d1">D建材分类1</van-checkbox>
                <van-checkbox name="d2">D建材分类2</van-checkbox>
                <van-checkbox name="d3">D建材分类3</van-checkbox>
                <van-checkbox name="d4">D建材分类4</van-checkbox>
                <van-checkbox name="d5">D建材分类5</van-checkbox>
                <van-checkbox name="d6">D建材分类6</van-checkbox>
                <van-index-anchor index="E"/>
                <van-checkbox name="e1">E建材分类1</van-checkbox>
                <van-checkbox name="e2">E建材分类2</van-checkbox>
                <van-checkbox name="e3">E建材分类3</van-checkbox>
                <van-checkbox name="e4">E建材分类4</van-checkbox>
                <van-checkbox name="e5">E建材分类5</van-checkbox>
                <van-checkbox name="e6">E建材分类6</van-checkbox>
            </van-checkbox-group>
        </van-index-bar>
        <div class="btns">
            <van-button hairline square plain type="primary" class="reset" color="#3c3f50" @click="checked = []">重置</van-button>
            <van-button square type="primary" class="affirm" color="#007df8" @click="classifyAffirm">完成</van-button>
        </div>
    </van-popup>
    <!-- 品牌弹窗 -->
    <van-popup class="indexBar"
        v-model:show="showBrand"
        position="right"
        :style="{ width: '84%', height: '100%' }">
        <div class="showBox">
            <div class="l">已选：</div>
            <div class="r">
                <div class="i">A品牌名称1</div>、
                <div class="i">B品牌名称3</div>、
                <div class="i">C品牌名称2</div>
            </div>
        </div>
        <van-index-bar>
            <van-checkbox-group v-model="brandChecked" shape="square">
                <van-index-anchor index="A"/>
                <van-checkbox name="a1">A品牌名称1</van-checkbox>
                <van-checkbox name="a2">A品牌名称2</van-checkbox>
                <van-checkbox name="a3">A品牌名称3</van-checkbox>
                <van-checkbox name="a4">A品牌名称4</van-checkbox>
                <van-checkbox name="a5">A品牌名称5</van-checkbox>
                <van-checkbox name="a6">A品牌名称6</van-checkbox>
                <van-index-anchor index="B"/>
                <van-checkbox name="b1">B品牌名称1</van-checkbox>
                <van-checkbox name="b2">B品牌名称2</van-checkbox>
                <van-checkbox name="b3">B品牌名称3</van-checkbox>
                <van-checkbox name="b4">B品牌名称4</van-checkbox>
                <van-checkbox name="b5">B品牌名称5</van-checkbox>
                <van-checkbox name="b6">B品牌名称6</van-checkbox>
                <van-index-anchor index="C"/>
                <van-checkbox name="c1">C品牌名称1</van-checkbox>
                <van-checkbox name="c2">C品牌名称2</van-checkbox>
                <van-checkbox name="c3">C品牌名称3</van-checkbox>
                <van-checkbox name="c4">C品牌名称4</van-checkbox>
                <van-checkbox name="c5">C品牌名称5</van-checkbox>
                <van-checkbox name="c6">C品牌名称6</van-checkbox>
                <van-index-anchor index="D"/>
                <van-checkbox name="d1">D品牌名称1</van-checkbox>
                <van-checkbox name="d2">D品牌名称2</van-checkbox>
                <van-checkbox name="d3">D品牌名称3</van-checkbox>
                <van-checkbox name="d4">D品牌名称4</van-checkbox>
                <van-checkbox name="d5">D品牌名称5</van-checkbox>
                <van-checkbox name="d6">D品牌名称6</van-checkbox>
                <van-index-anchor index="E"/>
                <van-checkbox name="e1">E品牌名称1</van-checkbox>
                <van-checkbox name="e2">E品牌名称2</van-checkbox>
                <van-checkbox name="e3">E品牌名称3</van-checkbox>
                <van-checkbox name="e4">E品牌名称4</van-checkbox>
                <van-checkbox name="e5">E品牌名称5</van-checkbox>
                <van-checkbox name="e6">E品牌名称6</van-checkbox>
            </van-checkbox-group>
        </van-index-bar>
        <div class="btns">
            <van-button hairline square plain type="primary" class="reset" color="#3c3f50" @click="brandChecked = []">重置</van-button>
            <van-button square type="primary" class="affirm" color="#007df8" @click="brandAffirm">完成</van-button>
        </div>
    </van-popup>
  </div>
</template>
<script setup>
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
const router = useRouter();
const active = ref(0);
const value = ref('');
const finished = ref(false);
const listLoad = ref(true);
const isScrolled = ref(false);
const showRight = ref(false);
const showClassify = ref(false);
const checked = ref([]);
const classifyText = ref('');
const showBrand = ref(false);
const brandChecked = ref([]);
const brandText = ref('');
const brandAct = ref([]);
 
// 清空搜索框
function clearIpt(){
    value.value = ""
}

// 加载更多
const onLoad = () => {
  if(listLoad.value) return
  seekFn()
}

// 搜索
const seekFn = async() => {

}

// 获取数据列表
const getList = async (num) => {
    active.value = num;
}

// 筛选
const filtrateFn = () => {
    showRight.value = true;
}

// 分类弹窗确认
const classifyAffirm = () => {
    showClassify.value = false;
    classifyText.value = checked.value.join('、');
}

// 品牌弹窗确认
const brandAffirm = () => {
    showBrand.value = false;
    brandText.value = brandChecked.value.join('、');
}

// 品牌单项选择
const brandSelect = (num) => {
    let exists = brandAct.value.includes(num);
    if(exists){
        brandAct.value = brandAct.value.filter(n => n != num)
    }else{
        brandAct.value.push(num)
    }
}

onMounted(() => {
    window.addEventListener('scroll',function(){
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        isScrolled.value = scrollTop > 60;
    })
})
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  padding-top: 1.9rem;
  background: #f3f4f6;
}
.topBox {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 90;
  width: 100%;
  background: #fff;
  &.on{
    box-shadow: 0 0 .2rem rgba(0,0,0,.1);
  }
  .seek {
    display: flex;
    align-items: center;
    padding: .23rem .3rem;
    height: 1.1rem;
    border-bottom: .01rem solid #f3f4f6;
    .ipt {
      position: relative;
      flex: 1;
      input {
        border-radius: 0.64rem;
        border: 0 none;
        width: 100%;
        height: 0.64rem;
        padding: 0.1rem 0.3rem 0.1rem 0.7rem;
        line-height: 0.44rem;
        font-size: 0.24rem;
        color: #3c3f50;
        background: #f5f6f8;
      }
      .clear {
        position: absolute;
        right: .1rem;
        top: 0;
        width: 0.64rem;
        height: 0.64rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAVCAYAAABG1c6oAAACHklEQVQ4jZ3Uy4vNcRjH8dfMKJdyL8NmNggxLkORu43LipVSFnJbCGWFYuQWImHhMvJHWLgnGwsiuYVxT64bE+MaY/TkOXU6nTOGp06d+v6+7+/n8/k+z7eqvqG+3Z86jF146d+qB+ZgC8bU1A6qrcIQzEA3XMOXTiKrMR2NGI+LAXyIDxiNqeiFO/jYCdg8bMVEnMamALbiEb4ldGYqjYNaKsBifT42YBLOYSeuBjA++Iq7+IRxab8Gt9FaAovM5mJ9KrsQynAlFgvAqB9oTqsNRZleL8o08p6FzQk7nypvor0UGPUdzxMwFlPQN08PF7OxLWFnUmU4aysASoES1lyU6bRUWoc1mIyz2JHq24s3d6kQ+ns0peJ1WFsQkLcZlm+U21hOYXGmD9LqCPTDZazG/VJlhaquRMsNHxM0MC8k/nctzuxfFPbHUixP+JvMMeBv8aLcpkoZ9sFibM9vmrL5l2AhuuNzTtTPzihckSPVG4fydzmza8gRrct2aukIWJ2hb0zL+3ECj1NJ2H6CkfkoDM0We10OGIEvy5boiSPYh2dFB7YlPDIclj06AK8KmRYyDGuLcgriFo9hN95VyPhk9ugBLEhOzPy9gsKV2fkBPoij+dCW7bWsWL+FCTmig+MtDeAerMrM4sTj+XR1BIuKTAMaGUbjR6bDAxjPT8xq2Nxbktnf6heeZqaj4m0M73HtlxIY4f5PnUItGn8DqkWLjjH+3jQAAAAASUVORK5CYII=)
          no-repeat center;
        background-size: 0.2rem;
      }
      .icon {
        position: absolute;
        left: .3rem;
        top: .19rem;
        width: 0.26rem;
        height: 0.26rem;
        background: url(~@/assets/images/iconSeek.png) no-repeat center;
        background-size: 0.26rem;
      }
    }
    .btn {
        width: .7rem;
        text-align: center;
        font-size: 0.24rem;
        color: #3c3f50;
        height: 0.64rem;
        line-height: 0.64rem;
        white-space: nowrap;
        margin-left: .3rem;
    }
  }
  .cTabs{
    ul{
        height: .8rem;
        display: flex;
        justify-content: space-around;
    }
    li{
        padding: 0 .3rem;
        em{
            font-size: .22rem;
            color: #3c3f50;
            display: block;
            height: .8rem;
            line-height: .8rem;
        }
        &.on{
            em{
                color: #007df8;
            }
        }
        &.it3{
            em{
                padding-right: .18rem;
                background: url(~@/assets/images/iconOrder.png) no-repeat right center;
                background-size: .12rem;
            }
        }
        &.it4{
            em{
                padding-right: .22rem;
                background: url(~@/assets/images/iconFiltrate.png) no-repeat right center;
                background-size: .15rem;
            }
        }
    }
  }
}
main {
  position: relative;
  z-index: 5;
  padding: .6rem .3rem .3rem;
}
.list{
    .li{
        position: relative;
        padding: .2rem;
        border-radius: .2rem;
        background: #fff;
        box-shadow: 0 0 .3rem rgba(0,0,0,.03);
        margin-bottom: .3rem;
        .a{
            display: flex;
        }
        .dt{
            width: 2.3rem;
            overflow: hidden;
            border-radius: .2rem;
            margin-right: .24rem;
        }
        .dd{
            flex: 1;
            padding-right: .2rem;
            h4{
                font-size: .26rem;
                line-height: .36rem;
                color: #3c3f50;
                font-weight: 600;
                margin-bottom: .1rem;
            }
            .supplier{
                font-size: .22rem;
                line-height: .32rem;
                color: #5b5d6a;
                margin-bottom: .1rem;
            }
            .price{
                font-size: .28rem;
                color: #e62a2b;
                font-family: 'DIN-Bold';
                line-height: 1;
                margin-bottom: .1rem;
            }
            .p{
                font-size: .22rem;
                line-height: .32rem;
                color: #5b5d6a;
                margin-bottom: .1rem;
                em{
                    font-family: 'DIN-Bold';
                }
            }
            .labels{
                display: flex;
                .i{
                    height: .4rem;
                    line-height: .4rem;
                    padding: 0 .2rem;
                    white-space: nowrap;
                    font-size: .22rem;
                    color: #007df8;
                    background: #e5f2fe;
                    border-radius: .4rem;
                }
            }
        }
        .addShopping{
            position: absolute;
            right: .4rem;
            bottom: .2rem;
            width: .6rem;
            height: .6rem;
            background: #fce9e9 url(~@/assets/images/shop/iconShoppingCart.png) no-repeat center;
            background-size: .24rem;
            border-radius: 50%;
        }
    }
}
.filtrateRight{
    padding: 0 .3rem;
    .box{
        position: relative;
        border-top: .01rem solid #eee;
        padding: .3rem 0;
        &:first-child{
            border: 0 none;
        }
        .til{
            font-size: .26rem;
            color: #3c3f50;
            line-height: .46rem;
            font-weight: 600;
        }
        .moreBtn{
            position: absolute;
            right: 0;
            top: .23rem;
            height: .6rem;
            line-height: .6rem;
            margin: auto 0;
            color: #5b5d6a;
            padding-right: .22rem;
            background: url(~@/assets/images/iconMore.png) no-repeat right;
            background-size: .1rem;
        }
        .p{
            font-size: .26rem;
            line-height: .46rem;
            color: #3c3f50;
            margin-top: .2rem;
        }
        .scope{
            margin-top: .2rem;
            display: flex;
            align-items: center;
            padding: .1rem .16rem;
            background: #f5f6f8;
            input{
                flex: 1;
                display: block;
                height: .6rem;
                width: 2.4rem;
                line-height: .4rem;
                padding: .1rem .2rem;
                font-size: .22rem;
                color: #333;
                border: 0 none;
            }
            .line{
                width: .15rem;
                height: .02rem;
                background: #3c3f50;
                margin: 0 .15rem;
            }
        }
        ul{
            display: flex;
            flex-wrap: wrap;
            margin-top: .2rem;
        }
        li{
            width: 1.35rem;
            height: .6rem;
            padding: 0 .1rem;
            background: #f5f6f8;
            margin: 0 .1rem .1rem 0;
            transition: all .3s;
            &:nth-child(4n){
                margin-right: 0;
            }
            em{
                display: block;
                font-size: .22rem;
                color: #999;
                line-height: .6rem;
                white-space: nowrap;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                transition: all .3s;
            }
            &.act{
                background: #007df8;
                em{
                    color: #fff;
                }
            }
            &.all{
                background: #e5f2fe;
                em{
                    color: #007df8;
                    padding-right: .14rem;
                    background: url(~@/assets/images/iconMore2.png) no-repeat right;
                    background-size: .08rem;
                }
            }
        }
    }
}
.indexBar{
    display: flex;
    flex-direction: column;
    padding: 0 .3rem;
    overflow: hidden;
    .showBox{
        padding: .3rem 0;
        display: flex;
        font-size: .26rem;
        line-height: .46rem;
        color: #3c3f50;
        border-bottom: .01rem solid #eee;
        .l{
            white-space: nowrap;
        }
        .r{
            display: flex;
            flex-wrap: wrap;
        }
    }
    :deep(.van-index-bar){
        flex: 1;
        overflow: hidden;
        overflow-y: auto;
        margin-right: -.3rem;
        padding-right: .3rem;
        .van-index-anchor--sticky{
            left: 0 !important;
        }
        .van-index-anchor{
            height: 0;
            opacity: 0;
            overflow: hidden;
        }
        .van-checkbox{
            height: .8rem;
            line-height: .8rem;
            font-size: .22rem;
            color: #3c3f50;
            border-bottom: .01rem solid #eee;
            .van-checkbox__icon{
                width: .24rem;
                height: .24rem;
                .van-icon{
                    width: 100%;
                    height: 100%;
                    font-size: .2rem;
                    line-height: .2rem;
                    border: .02rem solid #c6cbd2;
                    border-radius: .04rem;
                    
                }
            }
            .van-checkbox__icon--checked .van-icon{
                border-color: #007df8;
                background-color: #007df8;
            }
        }
    }
    .btns{
        display: flex;
        padding: .2rem .3rem;
        background: #fff;
        .van-button{
            height: .8rem;
            font-size: .28rem;
        }
        .reset{
            width: 1.9rem;
            margin-right: .2rem;
        }
        .affirm{
            flex: 1;
        }
    }
}
</style>
  
  