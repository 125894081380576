<template>
  <div class="appMain" :style="{ paddingTop: $store.state.safetyTop + 'px'}">
    <router-view v-slot="{ Component }">
      <keep-alive v-if="!$route.meta.keepAlive">  
        <component :is="Component"></component>  
      </keep-alive>  
      <component v-else :is="Component" :key="$route.fullPath"></component>
    </router-view>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { useStore } from 'vuex';
const store = useStore();

// 移动设备的安全区
function callBackSafeArea(top,right,bottom,left,density){
  let num = top || 0;
  let ratio = density;
  let resNum = Math.ceil(num / ratio);
  store.commit('setSafetyArea', resNum);
}
const getMobileInfo = () => {
  let top = store.state.safetyTop;
  if(top === null){
    window.location.href = "bridge:/base/getSafeArea/pageInit"
  }
}
onMounted(function(){
  window.callBackSafeArea = callBackSafeArea;
  // 测试
  // callBackSafeArea()
  getMobileInfo();
})
</script>
