<template>
  <div class="wrap">
    <NavBer title="商品详情"></NavBer>
    <div class="ban">
      <swiper
        :pagination="pagination"
        :modules="modules"
        :loop="true"
        class="banSwiper"
      >
        <swiper-slide>
          <img src="~@/assets/images/shop/shopDpic.jpg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img src="~@/assets/images/shop/shopDpic.jpg" alt="" />
        </swiper-slide>
        <swiper-slide>
          <img src="~@/assets/images/shop/shopDpic.jpg" alt="" />
        </swiper-slide>
      </swiper>
    </div>
    <main>
      <div class="info">
        <div class="price">¥188</div>
        <h4>
          全自动红外线智能感应水龙头冷热单冷台盆面盆洗手盆龙头盆面盆洗手盆龙头
        </h4>
        <p>生产厂家：广西渠通科技有限公司</p>
        <p>产品规格：700.0g/双</p>
        <p>成分含量：尼龙线棉纱700g</p>
      </div>
      <div class="shopInfo">
        <div class="icon"></div>
        <div class="txt">
          <div class="row">
            <div class="tag">旗舰</div>
            <div class="name">会采商城旗舰店</div>
          </div>
          <p>地址：广西壮族自治区/南宁市</p>
        </div>
        <div class="enter">进店</div>
      </div>
      <div class="hd">
        <ul>
          <li>商品详情</li>
          <li>平台优势</li>
        </ul>
      </div>
      <div class="bd">
        <div class="item"></div>
        <div class="item"></div>
      </div>
    </main>
  </div>
</template>
<script setup>
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";
import NavBer from "@/components/NavBer";
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";

const modules = [Pagination];
const pagination = {
  clickable: true,
};
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  background: #f3f4f6;
}
main {
  position: relative;
  z-index: 5;
  padding: 0 .3rem .3rem;
  margin-top: -0.4rem;
}
.ban {
  :deep(.swiper) {
    .swiper-pagination {
      height: 0.06rem;
      line-height: 0.06rem;
      bottom: 0.74rem;
      width: auto;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 0.06rem;
      background: rgba(255, 255, 255, 0.5);
      font-size: 0;
      .swiper-pagination-bullet {
        vertical-align: top;
        margin: 0;
        width: 0.44rem;
        height: 100%;
        border-radius: 0.44rem;
        opacity: 0;
        transition: all 0.3s;
        background: #fff;
      }
      .swiper-pagination-bullet-active {
        opacity: 1;
      }
    }
  }
}
.info{
  border-radius: .2rem;
  background: #fff;
  margin-bottom: .2rem;
}
.shopInfo{
  background: #fff;
  margin-bottom: .2rem;
}
</style>
  
  