<template>
  <div class="wrap">
    <NavBer title="我的课程"></NavBer>
    <main>
      <div class="li">
        <video-player
          class="viewBox"
          :src="videoUrl"
          poster="http://hjt-door202405.oss-test-cn-nnct.caih.local/hjt-door/202405/gw/hjt/2024051711405999c26316-ffb7-4066-b3b7-e8398372ec31.jpg"
          :controls="true"
          :autoplay="true"
          :volume="0.6"
        />
        <div class="dd">
          <h4>房建基础知识篇</h4>
          <p>施工现场安全培训干货总结货总结货总结货总结货总结</p>
        </div>
      </div>
    </main>
  </div>
</template>
<script setup>
import NavBer from '@/components/NavBer';
import { ref } from 'vue';
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'

const videoUrl = ref('https://res.qingtongcs.com/3d0b9811876d4d289d671ef736f5e6f9.mp4');
</script>
<style scoped lang="scss">
.wrap {
  position: relative;
  background: #f3f4f6;
}
main{
    padding: .6rem .3rem;
    .li{
      overflow: hidden;
      border-radius: .2rem;
      background: #fff;
      margin-bottom: .3rem;
      &:last-child{
        margin-bottom: 0;
      }
      .dd{
        padding: .2rem .3rem;
        h4{
          font-size: .28rem;
          color:#43465a;
          line-height: .38rem;
        }
        p{
          font-size: .22rem;
          color:#5b5d6a;
          line-height: .36rem;
        }
      }
    }
}
:deep(.viewBox){
  width: 100%;
  height: auto;
  .vjs-tech{
    position: static;
    height: auto;
  }
}

</style>
  
  