<template>
    <div :class="['navBer', {'on': isScrolled}]" :style="{ height: 'calc(1.1rem + ' + $store.state.safetyTop + 'px)'}">
        <div class="retBtn" @click="onClickLeft"></div>
        <h1>{{title}}</h1>
    </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
const onClickLeft = () => history.back();
import { defineProps } from 'vue';
const props = defineProps({
    title: String
})
const isScrolled = ref(false);

onMounted(() => {
    window.addEventListener('scroll',function(){
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        isScrolled.value = scrollTop > 60;
    })
})
</script>
<style scope lang="scss">
.navBer{
    position: fixed;
    left: 0;
    top: 0;
    height: 1.1rem;
    width: 100%;
    z-index: 998;
    background: #fff;
    display: flex;
    align-items: flex-end;
    &.on{
        box-shadow: 0 0 .2rem rgba(0,0,0,.1);
    }
    .retBtn{
        position: absolute;
        left: .06rem;
        bottom: .1rem;
        width: .9rem;
        height: .9rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAeCAYAAAAl+Z4RAAAAmklEQVQ4jaXVOxKAIAxF0eB+oND9L8GGDeFowcgn5OVBf48gMIRSijAjXekLj52YAv6xG+jjfOcAA7MYnoEWQ8AqNgErXgJIrAJoPAU88QB44wZg4gqw8TtCPCMdC3sbG6D/Yr8caAY7SF0CizT/gEGGn+hFprvgQdRtRJHlOUAQ8yBZCHQSVwh8lDXE/bRtPSyzmVC3sSIi8gA78G1NX/ltRAAAAABJRU5ErkJggg==) no-repeat center;
        background-size: .16rem;
    }
    h1{
        width: 100%;
        font-size: .3rem;
        color: #2d322d;
        line-height: 1.1rem;
        text-align: center;
        font-weight: 600;
    }
}
</style>
  
  